module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Reshopper","short_name":"Reshopper","start_url":"/","background_color":"#9DC9E3","theme_color":"#9DC9E3","display":"fullscreen","icon":"static/icon.png","prefer_related_applications":true,"related_applications":[{"platform":"play","id":"com.reshopper.reshopper"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7be679ffcfd66a803c67c0442a9ac3e9"},
    }]
